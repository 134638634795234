/**
 * mixins Created by LiuSiyao on 2021/3/23.
 */
import _ from 'lodash'
import IcsSearchInner from '@/components/search-inner'
import IcsTableInner from '@/components/table-inner'
import IcsSearchDate from '@/components/search-date'
import IcsButtonInner from '@/components/button-inner'
import IcsItemInner from '@/components/item-inner'
import IcsPageInner from '@/components/page-inner'
export const autoHeightMixin = {
  data: function () {
    return {
      maxHeight: 'auto',
      offsetHeight: 55
    }
  },
  mounted () {
    this.setMaxHeight()
    window.addEventListener('resize', this.setMaxHeight)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.setMaxHeight)
  },
  methods: {
    setMaxHeight: _.debounce(function () {
      const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
      let header = document.getElementsByClassName('header')
      let pageTitle = document.getElementsByClassName('page-title')
      if (header) {
        header = document.getElementsByClassName('header')[0]
      }
      if (pageTitle) {
        pageTitle = document.getElementsByClassName('page-title')[0]
      }
      const headerHeight = header.offsetHeight || 0
      const pageTitleHeight = pageTitle.offsetHeight || 0
      this.maxHeight = windowHeight - headerHeight - pageTitleHeight - this.offsetHeight
    }, 300)
  }
}
export const basePageListMixin = {
  components: {
    IcsSearchInner,
    IcsTableInner,
    IcsSearchDate
  },
  data () {
    return {
      changeRouter: true,
      exportApi: null,
      baseSearchForm: {},
      searchForm: {},
      tableList: {
        list: []
      },
      basePage: {
        pageNum: 1,
        pageSize: 10
      },
      loading: {
        list: false,
        btn: false,
        export: false
      }
    }
  },
  created () {
    this.searchForm = _.cloneDeep({ ...this.basePage, ...this.baseSearchForm })
    this.recoverSearch()
  },
  methods: {
    // 搜索条件回填
    recoverSearch () {
      const newForm = Object.assign(_.cloneDeep(this.searchForm), this.$route.query)
      this.$set(this, 'searchForm', newForm)

      // 用于页面初始化时拉取列表数据
      this.getList()
    },
    search () {
      this.searchForm.pageNum = 1
      if (this.changeRouter) {
        this.$router.push({ query: this.searchForm })
      }
      this.getList()
      return false
    },
    getList () {
    },
    exportFile ({ fieldNames }) {
      if (this.exportApi) {
        this.loading.export = true
        this.exportApi(this.searchForm, { fieldNames: fieldNames }).then(result => this.utils.exportFile(result)).finally(() => {
          this.loading.export = false
        })
      }
    },
    // 清空搜索
    clearSearch () {
      this.searchForm = _.cloneDeep({ ...this.basePage, ...this.baseSearchForm })
      if (this.changeRouter) {
        this.$router.push({ query: this.searchForm })
      }
      this.getList()
      return false
    },
    changePageNum ({ pageNum }) {
      this.searchForm.pageNum = pageNum
      if (this.changeRouter) {
        this.$router.push({ query: this.searchForm })
      }
      this.getList()
    },
    indexMethod (index) {
      if (this.tableList.pageNum > 1) {
        const pageNUm = (this.tableList.pageNum - 1) * 10
        return index + 1 + pageNUm
      } else {
        return index + 1
      }
    }
  }
}
export const routeEnterMixin = {
  beforeRouteEnter (to, from, next) {
    const actions = new Map([
      ['add', '新增'],
      ['edit', '编辑'],
      ['detail', '详情'],
      ['agency', '(代)准入'],
      ['supplement', '补件'],
      ['audit', '审批']
    ])
    if (to.params.editMode) {
      const action = actions.get(to.params.editMode)
      to.meta.title = `${action}`
    }
    next()
  },
  components: { IcsButtonInner, IcsItemInner, IcsPageInner },
  created () {
    this.requiredChange()
  },
  data () {
    return {
      loading: {
        list: false,
        detail: false,
        submit: false
      }
    }
  },
  methods: {
    requiredChange () {
      if (!['add', 'edit', 'agency'].includes(this.$route.params.editMode)) {
        if (this.rules) {
          this.utils.requiredChange(false, this.rules)
        }
      }
    },
    viewEdit () {
      return ['add', 'edit', 'agency', 'binding'].includes(this.$route.params.editMode)
    },
    changeRules ({ name, required = false, max = 20, number = false }) {
      const arr = []
      if (required) {
        arr.push(this.rulesToolkit.rules.required({ name }))
      }
      if (number) {
        arr.push(this.rulesToolkit.rules.number({ name }))
      }
      arr.push(this.rulesToolkit.rules.range({ name, max }))
      return arr
    },
    changeRulesDecimals ({ name, required = false, maxLength = 2 }) {
      const arr = []
      if (required) {
        arr.push(this.rulesToolkit.rules.required({ name }))
      }
      arr.push(this.rulesToolkit.rules.number({ name }))
      arr.push(this.rulesToolkit.rules.pattern({ pattern: this.constants.regExpRules[`decimal${maxLength}`].regExp, message: `${name}${this.constants.regExpRules[`decimal${maxLength}`].message}` }))
      return arr
    }
  }
}
