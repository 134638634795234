<!-- table-inner -->
<template>
  <el-col :span="24" class="tc btn-unified">
    <slot name="btn-before" />
    <el-button v-if="showCancel" :loading="loading" @click="cancel">
      {{ cancelTitle }}
    </el-button>
    <slot name="btn-center" />
    <debounce-button v-if="showSubmit" type="primary" :loading="loading" @click="submit">
      {{ submitTitle }}
    </debounce-button>
    <slot name="btn-behind" />
  </el-col>
</template>
<script>
export default {
  name: 'IcsButtonInner',
  components: {
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    showCancel: {
      type: Boolean,
      default: true
    },
    showSubmit: {
      type: Boolean,
      default: true
    },
    submitTitle: {
      type: String,
      default: '保 存'
    },
    cancelTitle: {
      type: String,
      default: '取 消'
    }
  },
  data () {
    return {
    }
  },
  watch: {
  },
  created () {
  },
  methods: {
    cancel () {
      this.$router.back()
      this.$emit('cancel')
    },
    submit () {
      this.$emit('submit')
    }
  }
}
</script>
<style>
</style>
