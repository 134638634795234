var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-area" }, [
    !_vm.showHeader
      ? _c(
          "div",
          { staticClass: "area-title" },
          [
            _c("p", { staticClass: "title" }, [
              _vm._v(" " + _vm._s(_vm.title) + " ")
            ]),
            _vm._t("btn-inner")
          ],
          2
        )
      : _vm._e(),
    _c(
      "div",
      { class: _vm.viewEdit() ? "form-inner" : "detail-inner" },
      [
        _vm._t("special-inner"),
        _c("el-row", { attrs: { gutter: _vm.gutter } }, [_vm._t("default")], 2)
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }